import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { END } from "redux-saga";

import CMSPageHead from "../../components/common/cms/cms-page-head";
import DesktopMicroSite from "../../components/common/desktop-microsite";
import { FnpPageType, setFnpPageType } from "../../src/utils/common";
import { requestHomePageBodyData, errorHomePageBodyData } from "../../src/actions/home-actions";
import wrapper from "../../redux/store/store";
import { requestWidgetHelperData } from "../../src/actions/common-page-actions";
import useCommonOmnitureSetup from "../../components/hooks/analytics/useCommonOmnitureSetup";
import { useSetCurrencyDetailsToWindow, useReloadWidgetsPincodeChange } from "../../src/utils/hooks";
import { timeIntervalForImpAndClickEvTracking } from "../../src/services/api/common/common-api";
import useClevertapSetup from "../../components/hooks/analytics/clevertap/useCleverTapSetup";
import { cleverTapConfig } from "../../src/analytics/clevertapConstants";

/**
 * This method brings in all data which has to be displayed on the home page
 *
 * @returns {React.ReactElement} jsx for the home page.
 */
function Home() {
  useSetCurrencyDetailsToWindow();
  useReloadWidgetsPincodeChange();
  const { breadcrumbs } = useSelector((state) => state.commonPageData);
  const { pageBodyData, accordionData, trendingNowWidget } = useSelector((store) => store.homePageData);
  const microSiteData = {
    microSite: `${pageBodyData} <section id="footerSEO">${accordionData}</section>`,
    pageType: FnpPageType.HOME,
    breadcrumbs,
  };

  useEffect(() => {
    setFnpPageType(FnpPageType.HOME);
  }, []);

  useEffect(() => {
    const nav = document.getElementById("navmenubar");
    let lastOffset = 0;
    /**
     * this method will hide/show the top menu on scroll
     *
     */
    const handleScroll = () => {
      const isOpened = nav.getElementsByClassName("opened").length;
      const currentPageOffset = window.pageYOffset;
      if (isOpened || currentPageOffset < 0 || lastOffset < 0) {
        return;
      }
      if (currentPageOffset > lastOffset) {
        nav.classList.add("hide");
      } else {
        nav.classList.remove("hide");
      }
      lastOffset = currentPageOffset;
    };
    if (nav) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useCommonOmnitureSetup();
  useClevertapSetup(cleverTapConfig.clevertapAccountId, cleverTapConfig.clevertapRegion);
  return (
    <>
      <CMSPageHead />
      <DesktopMicroSite microSiteData={microSiteData} widgets={trendingNowWidget} />
    </>
  );
}

Home.getInitialProps = wrapper.getInitialPageProps((store) => async (ctx) => {
  const isServer = typeof window === "undefined";
  // for getting trace id serverside logs in kibana
  if (isServer && global) {
    global.pageContext = ctx;
  }
  try {
    const contentOptions = {
      contentURL: "home",
      headers: ctx?.req.headers,
      ctx,
      setResHeaders: true,
    };
    if (ctx.req) {
      await store.dispatch(requestWidgetHelperData());
      await store.dispatch(requestHomePageBodyData(contentOptions));
      await timeIntervalForImpAndClickEvTracking(ctx, false);
    }
  } catch (error) {
    store.dispatch(errorHomePageBodyData(error.message));
  }
  if (ctx.req) {
    // Stop the saga if on server
    store.dispatch(END);
    await store.sagaTask.toPromise();
  }
  return {};
});

export default Home;
